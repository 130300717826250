export const constants = {
  pages: {
    CREATE_COURSE: "create-course",
    UPDATE_COURSE: "update-course",
  },
  Initial_Value: "TKLOptionPlaceholder123",
  stepTypeLearnAboutIt: "Learn About It!",
  connectionStatus: {
    PENDING: "PENDING",
    ACCEPTED: "ACCEPTED",
    REJECTED: "REJECTED",
  },
  chatSetUpKey: "chatSetUp",
};
