import { RegistrationApplicationData } from "../components/Models/course-model";
import { types } from "./types";
const initialState = {
  posts: "",
  isCommunityReload: false,
  bannerImages: {},
  isBannerReload: false,
  trendingCourses: [],
  listOfGroups: [],
  listOfMyProGroups: [],
  listOfInvitedGroups: [],
  isGroupReload: false,
  postsOfGroups: [],
  pollCreation: {
    pollOpenDate: "",
    pollCloseDate: "",
    pollQuestion: "",
    isOnlyOption: true,
    options: [{ name: "", isCorrectAnswer: false }],
  },
  ViewMonthlyPoll: {
    pollOpenDate: new Date(),
    pollCloseDate: new Date(),
    answerSubmitted: false,
    pollQuestion: "",
    isOnlyOption: false,
    totalResponses: 0,
    options: [
      {
        name: ``,
        isCorrectAnswer: false,
        optionSelected: false,
        optionTimes: 0,
      },
      {
        name: "",
        isCorrectAnswer: false,
        optionSelected: false,
        optionTimes: 0,
      },
    ],
  },
  allPollData: [],
  followedUser: [],
  followingUsers: [],
  colleagues: [],
  myConnections: [],
  addMoreContacts: [],
  memberInvitations: [],
  pendingInvitations: [],
  isColleaguesReload: false,
  isResourseReload: false,
  resourses: [],
  forums: [],
  isForumReload: false,
  bookmarkedPosts: [],
  isBookmarkedPostReload: false,
  groupInviteUserList: [],
};
export const community = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_RESOURCE:
      return {
        ...state,
        resourses: action.payload,
      };
    case types.RESOURCE_RELOAD:
      return {
        ...state,
        isResourseReload: action.payload,
      };

    case types.GET_POSTS:
      return {
        ...state,
        posts: action.payload,
      };

    case types.GET_FORUMS:
      return {
        ...state,
        forums: action.payload,
      };
    case types.FORUM_RELOAD:
      return {
        ...state,
        isForumReload: action.payload,
      };

    case types.COMMUNITY_RELOAD:
      return {
        ...state,
        isCommunityReload: action.payload,
      };

    case types.GET_BANNERS:
      return {
        ...state,
        bannerImages: action.payload,
      };

    case types.GROUP_INVITE_USER_LIST:
      return { ...state, groupInviteUserList: action.payload };
    case types.MY_CONNECTIONS:
      return { ...state, myConnections: action.payload };
    case types.ADD_MORE_CONTACTS:
      return { ...state, addMoreContacts: action.payload };
    case types.MEMBER_INVITATIONS:
      return { ...state, memberInvitations: action.payload };
    case types.PENDING_INVITATIONS:
      return { ...state, pendingInvitations: action.payload };

    case types.FOLLOWED_USERS:
      return {
        ...state,
        followedUsers: action.payload,
      };
    case types.FOLLOWING_USERS:
      return {
        ...state,
        followingUsers: action.payload,
      };
    case types.BANNER_RELOAD:
      return {
        ...state,
        isBannerReload: action.payload,
      };

    case types.LIST_OF_GROUPS:
      return {
        ...state,
        listOfGroups: action.payload,
      };

    case types.LIST_OF_MY_PRO_GROUPS:
      return {
        ...state,
        listOfMyProGroups: action.payload,
      };

    case types.LIST_OF_MESSAGES:
      return {
        ...state,
        listOfMessages: action.payload,
      };

    case types.IS_MESSAGES_RELOAD:
      return {
        ...state,
        isMessagesReload: action.payload,
      };

    case types.IS_MY_PRO_GROUPS_RELOAD:
      return {
        ...state,
        isMyGroupsReload: action.payload,
      };

    case types.LIST_OF_INVITED_GROUPS:
      return {
        ...state,
        listOfInvitedGroups: action.payload,
      };

    case types.LIST_OF_POSTS_BY_GROUP:
      return {
        ...state,
        postsOfGroups: action.payload,
      };

    case types.IS_GROUP_RELOAD:
      return {
        ...state,
        isGroupReload: action.payload,
      };

    case types.GET_TRENDING_COURSES:
      return {
        ...state,
        trendingCourses: action.payload,
      };

    case types.PROFESSIONAL_COLLEAGUES:
      return {
        ...state,
        colleagues: action.payload,
      };

    case types.PROFESSIONAL_COLLEAGUES_RELOAD:
      return {
        ...state,
        isColleaguesReload: action.payload,
      };

    case types.RESUABLE_COMMUNITIY_REDUCER:
      return {
        ...state,
        [action.label]: action.payload,
      };

    case types.BOOKMARKED_POST_RELOAD:
      return {
        ...state,
        isBookmarkedPostReload: action.payload,
      };
    case types.CREATE_POLL_ADD_OPTION:
      return {
        ...state,
        [action.parentLabel]: {
          ...state[action.parentLabel],
          [action.label]: [
            ...state[action.parentLabel][action.label],
            action.payload,
          ],
        },
      };

    case types.CREATE_POLL_DELETE_OPTION:
      return {
        ...state,
        [action.parentLabel]: {
          ...state[action.parentLabel],
          [action.label]: [
            ...state[action.parentLabel][action.label].slice(0, action.payload),
            ...state[action.parentLabel][action.label].slice(
              action.payload + 1
            ),
          ],
        },
      };

    case types.CREATE_POLL_EDIT_OPTION:
      let data = {};
      if (action?.makeResuableAction) {
        data = action?.data;
      } else {
        data = {
          name: action.payload,
          isCorrectAnswer: action.isCorrectAnswer,
        };
      }

      return {
        ...state,
        [action.parentLabel]: {
          ...state[action.parentLabel],
          [action.label]: [
            ...state[action.parentLabel][action.label].slice(
              0,
              action.optionIndex
            ),
            data,
            ...state[action.parentLabel][action.label].slice(
              action.optionIndex + 1
            ),
          ],
        },
      };

    case types.CREATE_POLL_LABEL_CHANGE:
      let changedData = [];
      let dataPayload = {};
      state.pollCreation?.options.forEach((item) => {
        dataPayload = {
          name: item?.name || "",
          isCorrectAnswer: false,
        };
        changedData.push(dataPayload);
      });
      return {
        ...state,
        [action.parentLabel]: {
          ...state[action.parentLabel],
          [action.label]: action.payload,
          options: changedData,
        },
      };

    case types.CREATE_POLL_OPTIONS_HANDLER:
      return {
        ...state,
        [action.parentLabel]: {
          ...state[action.parentLabel],
          [action.label]: action.payload,
        },
      };
    default:
      return state;
  }
};
